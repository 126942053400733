import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Helmet from "react-helmet";
import config from "../utils/siteconfig";
import Container from "../components/container";
import PortfolioMainCard from "../components/pages/portfolioHomepageCard";
import H2Title from "../components/shared/h2-title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";

const Homepage = ({ data, props, location }) => {
  const {title} = data.projects
  const projects = data.projects.edges;
  return (
    <Layout>
      <Helmet>
        <title>{`${`Home`} - ${config.siteTitle}`}</title>
      </Helmet>
      <div className="grid gap-8 md:gap-10 lg:gap-6 md:grid-cols-12">
        <div className="md:col-span-12">
          <div className="flex justify-center w-full mt-24">
            <div className="flex-col text-center items-center flex">
              <img
                src="https://placehold.it/1500x1500"
                className="rounded-full w-36 md:w-48"
              />
              <h1 className="mt-4 text-3xl font-bold tracking-tight font-gotham text-whitney">
                Blake Powers
              </h1>
            </div>
          </div>
        </div>
      </div>
      <Container>
        <div className="grid gap-8 md:gap-10 lg:gap-6 md:grid-cols-12">
          <div className="col-span-12 mt-28">
            <div className="grid grid-cols-12 gap-8 ">
              {projects.map(({ node }) => {
                return (
                  <PortfolioMainCard
                    id={node.id}
                    projectMainImage={node.mainImage}
                    alt={node.mainImage.title}
                    title={node.title}
                    link={`/projects/${node.slug}`}
                  />
                );
              })}
            </div>
          </div>
          <div className="col-span-12">
            <Link
              to="/all-projects"
              className="text-lg font-bold text-indigo-500 hover:text-indigo-700 dark:hover:text-indigo-200 dark:text-indigo-400 font-whitney"
            >
              View All Projects <FontAwesomeIcon icon={faLongArrowAltRight} />
            </Link>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Homepage;

export const query = graphql`
  query {
    projects: allContentfulWork(
      limit: 12
      sort: { order: DESC, fields: dateCompleted }
    ) {
      edges {
        node {
          title
          slug
          mainImage {
            gatsbyImageData(width: 700, quality: 80)
          }
        }
      }
    }
  }
`;

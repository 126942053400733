import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const PortfolioMainCard = ({ id, ...props }) => {
  return (
    <div className="col-span-12 md:col-span-6 xl:col-span-4" key={props.id}>
      <div className="relative h-144 md:h-128 ">
        <Link to={props.link} className="group">
          <div className="absolute z-40 hidden transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 group-hover:block ">
            <h3 className="text-2xl font-black leading-tight text-white font-whitney">
              {props.title}
            </h3>
            <p></p>
          </div>
          <div className="absolute top-0 left-0 z-20 hidden w-full h-full group-hover:bg-opacity-75 group-hover:bg-gray-800 group-hover:block"></div>
          <GatsbyImage
            image={props.projectMainImage.gatsbyImageData}
            className="absolute top-0 left-0 z-10 object-contain w-full h-full duration-500 ease-in-out transform shadow-xl filter dark:brightness-95 hover:scale-105 motion-reduce:transition-none motion-reduce:transform-none"
            alt={props.alt}
          />
        </Link>
      </div>
    </div>
  );
};

export default PortfolioMainCard;
